.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.signup-page:after {
  position: absolute;
  content: "";
  background: #ffb600;
  width: 100%;
  height: 100vh;
  transform: skew(-23deg);
  left: 109px;
  top: 0;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
}
.border-signup {
  box-shadow: rgba(239, 242, 245, 1) 0px 0px 0px 4px;
  width: 468px;
  height: 64px;
  border-radius: 6px;
  padding: 8px;
  gap: 10px;
}

/* .logo-block-bg {
  background-image: url("assets/images/authpage-image.svg");
} */
