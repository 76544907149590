// Define breakpoints as variables
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

@media (min-width: 1439px) {
  .authPage {
    &.SignIn {
      .authLeft {
        .authTopContent {
          height: 250px;
          .inner {
          }
        }
        .authBottomContent {
          height: calc(100% - 250px);
        }
        .authLogo {
          bottom: 25px;
        }
      }
    }
    &.SignUp {
      .authLeft {
        .authTopContent {
          height: 250px;
          .inner {
          }
        }
        .authBottomContent {
          height: calc(100% - 250px);
        }
        .authLogo {
          top: 25px;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-sm) {
  .main-layout {
    padding: 16px;
  }
  .mobileDrawerToggle {
    position: fixed;
    top: 15px;
    left: 8px;
    z-index: 1111;
  }
  .topbar {
    padding: 8px 16px 8px 0px;
    justify-content: flex-end;
    .topbarRight {
      gap: 16px;
    }
  }
  .authPage {
    .authLeft {
      .authLeftContent {
        h2 {
          line-height: normal;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .authPage {
    display: block;
    height: 100%;
    .MuiGrid-root {
      display: block;
      .MuiGrid-item {
        display: block;
        max-width: 100%;
        height: 100%;
      }
    }
    &.SignIn {
      .authLeft {
        .authTopContent {
          height: 250px;
          h2 {
            line-height: normal;
            margin-bottom: 8px;
          }
        }
        .authBottomContent {
          display: none;
        }
      }
    }
    &.SignUp {
      .authLeft {
        .authTopContent {
          height: 250px;
          h2 {
            line-height: normal;
            margin-bottom: 8px;
          }
        }
        .authBottomContent {
          display: none;
        }
      }
    }
    .authLeft {
      .authLogo {
        display: none;
      }
    }
    .authForm {
      padding: 40px 16px !important;
    }
  }
}
