$white: #ffffff;
$surfaceBg: #edf0f3;
$themeRadius: 6px;
$themeBoxShadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

//error
$error: #85163d;

// primary
$primary: #003399;
$lightPrimary: #ebf6ff;

// secondary
$secondary: #ffb600;

// warning
$warning: #f89c47;

// success
$success: #50cd89;

// info
$info: #0787ff;

// text
$textDark: #101010;
$textSecondary: #474747;

// table
$lightGray: #eff2f5;
$tableStriped: rgba(237, 240, 243, 0.5);

// ==============================|| JAVASCRIPT ||============================== //

:export {
  white: $white;
  surfaceBg: $surfaceBg;
  themeRadius: $themeRadius;
  themeBoxShadow: $themeBoxShadow;

  //error
  error: $error;

  // primary
  primary: $primary;
  lightPrimary: $lightPrimary;

  // secondary
  secondary: $secondary;

  // warning
  warning: $warning;

  // success
  success: $success;

  // info
  info: $info;

  // text
  textDark: $textDark;
  textSecondary: $textSecondary;

  // table
  lightGray: $lightGray;
  tableStriped: $tableStriped;
}
