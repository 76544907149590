body {
  margin: 0px;
  padding: 0px;
}

/* theme color setup for text and background start */
.text-error {
  color: $error;
}
.text-primary {
  color: $primary;
}
.text-secondary {
  color: $secondary;
}
.text-warning {
  color: $warning;
}
.text-success {
  color: $success;
}
.text-info {
  color: $info;
}
.bg-error {
  background-color: $error;
}
.bg-primary {
  background-color: $primary;
}
.bg-secondary {
  background-color: $secondary;
}
.bg-warning {
  background-color: $warning;
}
.bg-success {
  background-color: $success;
}
.bg-info {
  background-color: $info;
}
.theme-boxshadow {
  box-shadow: $themeBoxShadow;
}
/* theme color setup for text and background end */

/* loader start */
.loader-overlay {
  position: fixed; /* Ensure it covers the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Make sure it's above all other content */
}

.spinner {
  position: relative;
  width: 80px; /* Size of the spinner */
  height: 80px; /* Size of the spinner */
}

.spinner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid rgb(255, 193, 14);
  border-top: 5px solid rgb(24, 70, 163);
  border-left: 5px solid rgb(24, 70, 163);
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.spinner img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.main-layout.blur {
  opacity: 0.5; /* Adjust the value to control how much you want to dim the content */
}

.loader-overlay img {
  width: 50px; /* Adjust the size as needed */
  animation: zoomInOut 2s ease-in-out infinite; /* Animation duration and repeat */
}
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1); /* Initial and final size */
  }
  50% {
    transform: scale(1.2); /* Zoom in to 120% */
  }
}
/* loader end */

/* table start */
.empty-records {
  table {
    min-height: calc(100vh - 293px);
  }
  &.dashboard-table {
    table {
      min-height: calc(100vh - 383px);
    }
  }
}
/* table end */

/* layout start */
.main-layout {
  background-color: $surfaceBg;
  padding: 24px;
  margin-top: 64px;
}
.topbar {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0px 24px 0px 0px;
  .topbarRight {
    display: flex;
    align-items: center;
    gap: 24px;
    .userChip {
      // border-radius: $themeRadius;
      // border: 1px solid $lightGray;
      // padding: 12px 16px;
      // color: $textDark;
      // font-size: 14px;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // cursor: pointer;
      // min-width: 190px;
      // line-height: normal;
    }
    .MuiSelect-select {
      padding-left: 0px !important;
    }
  }
}
.formGroup {
  margin-bottom: 16px;
}
.topbarPopover {
  .MuiPopover-paper {
    left: 0px !important;
    right: 0px !important;
    top: 64px !important;
    width: 100% !important;
    transform-origin: 0% 0% !important;
    max-width: 100% !important;
    border: 1px solid $lightGray;
    background-color: #f8fafc;
    border-radius: 0px;
  }
}
/* layout end  */

/* authPage start */
.authPage {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: $white;
  .MuiGrid-item {
    height: 100vh;
  }
  .authLeft {
    height: 100%;
    width: 100%;
    position: relative;
    .authTopContent {
      display: flex;
      align-items: center;
      .inner {
        max-width: 90%;
        margin: 0px auto;
        width: 100%;
      }
    }
    .authBottomContent {
      background-color: $primary;
      clip-path: polygon(0 0, 100% 0%, 40% 100%, 0% 100%);
    }
    .authLogo {
      position: absolute;
      width: 100%;
      padding: 0px 25px;
    }
  }
  &.SignIn {
    .authLeft {
      .authTopContent {
        background-color: $secondary;
        height: 150px;
        .inner {
        }
      }
      .authBottomContent {
        background-color: $primary;
        clip-path: polygon(0 0, 100% 0%, 40% 100%, 0% 100%);
        height: calc(100% - 150px);
      }
      .authLogo {
        bottom: 25px;
        left: 0px;
        text-align: right;
      }
    }
  }
  &.SignUp {
    .authLeft {
      .authTopContent {
        background-color: $primary;
        height: 150px;
        .inner {
        }
      }
      .authBottomContent {
        background-color: $secondary;
        clip-path: polygon(70% 0, 100% 0%, 100% 100%, 0% 100%);
        height: calc(100% - 150px);
      }
      .authLogo {
        top: 25px;
        left: 0px;
        text-align: left;
      }
    }
  }
}
/* authPage end */

/* Minimal Layout start */
.minimalHeader {
  padding: 30px;
  text-align: center;
  box-shadow:
    0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.minimalBody {
  padding: 80px 0px;
}
/* Minimal Layout End */

/* Setup wallmart account start */
.apiPointList {
  padding: 16px;
  border: 1px solid $lightPrimary;
  border-radius: $themeRadius;
  align-items: start;
  display: flex;
  align-items: start;
  gap: 8px;
  margin-top: 24px;
  font-size: 14px;
  p {
    font-size: 14px;
    color: $textSecondary;
  }
}
.accessTable {
  thead {
    tr {
      th {
        padding: 12px;
        font-size: 14px;
        font-weight: 600;
        color: $textDark;
        background-color: $surfaceBg;
        border: none;
        &:first-child {
          border-top-left-radius: $themeRadius;
        }
        &:last-child {
          border-top-right-radius: $themeRadius;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        background-color: $white !important;
        padding: 8px 12px;
        vertical-align: top;
        font-size: 14px;
      }
    }
  }
}
/* Setup wallmart account end */

.rs-picker {
  .rs-picker-input-group {
    background-color: #ebf6ff;
    border: none;
    &:focus,
    &:focus-within {
      outline: none !important;
    }
    .rs-input {
      padding: 12px 16px;
      background-color: #ebf6ff;
      border-radius: 6px 0 0px 6px;
      font-size: 1rem;
      color: #101010;
      &:focus,
      &:focus-within {
        outline: none !important;
      }
    }
    .rs-input-group-addon {
      background-color: #ebf6ff !important;
      border-radius: 0px 6px 6px 0px;
      font-size: 1rem;
      color: #101010;
    }
  }
}
